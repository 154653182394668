<template>
  <div v-if="iconName && iconName !== ''" class="" :class="cls">
    <i v-if="isElementIcon(iconName)" :class="`${iconName} ${iconCls}`"></i>
    <feather-icon v-else :icon="iconName" :size="size"></feather-icon>
  </div>
  <div v-else-if="defaultIcon && defaultIcon !== ''" class="" :class="cls">
    <i v-if="isElementIcon(defaultIcon)" :class="`${defaultIcon} ${iconCls}`"></i>
    <feather-icon v-else :icon="defaultIcon" :size="size"></feather-icon>
  </div>
  <span v-else class="text-muted">暂无</span>
</template>

<script>

export default {
  name: 'FeatherIconSelector',
  components: {
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    cls: {
      type: String,
      default: '',
    },
    iconCls: {
      type: String,
      default: '',
    },
    defaultIcon: {
      type: String,
      default: '',
    },
    // just feather icon
    size: {
      type: String,
      default: '14',
    },
    copyable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    icon() {
      this.iconName = this.icon
    },
  },
  data() {
    return {
      iconName: this.icon,
    }
  },
  methods: {
    isElementIcon(icon) {
      return icon && icon.substr(0, 7) === 'el-icon'
    },
  },
}
</script>

<style scoped>

</style>
